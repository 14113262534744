export const namespaced = true
import { useToast } from 'vue-toastification'
import axios from 'axios'

const toast = useToast()
export default {

    state : {
        item :'',
        items :'',   
        categories :'',   
    },

    getters : {
        item: (state )=> state.item,
        categories: (state )=> state.categories,
        items: (state )=> state.items,
    },

    mutations : { 
        setcategories :(state,categories ) => (state.categories = categories), 
        setitem :(state,item ) => (state.item = item), 
        setitems :(state,items ) => (state.items = items),
    },

    actions :{
        async get_item_categories( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_item_categories')
            return new Promise((resolve, reject) => {
            axios.post("/open/items/categories?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('setcategories', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_trending_items( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_trending_items')
            return new Promise((resolve, reject) => {
            axios.post("/open/items?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('setitems', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_item_details( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_item_details')
            return new Promise((resolve, reject) => {
            axios.post("/open/items/" + id, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('setitem', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }


}