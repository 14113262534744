let routes = [

    {
        path: "/auth/",
        props:true,
        component: () => import("@/views/auth/main.vue"),


        children: [
          {
            path: "login",
            name: "login",
            props:true,
            component: () => import("@/views/auth/login.vue"),
            meta: {
             title: 'Login to Your Dashboard'
            }
          },

          {
            path: "verify_device",
            name: "verify_device",
            props:true,
            component: () => import("@/views/auth/verify_device.vue"),
            meta: {
             title: 'Verify Your Device'
            }
          },

          {
            path: "verify_account/:number",
            name: "verify_account",
            props:true,
            component: () => import("@/views/auth/verify_account.vue"),
            meta: {
             title: 'Verify Account'
            }
          },
        ]
    },

]
export default routes;