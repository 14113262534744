import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './axios'
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"
import moment from 'moment'
import './assets/styles/tailwind.css'
// import "bootstrap/dist/css/bootstrap.min.css"
// import "bootstrap/dist/js/bootstrap.js"
// import "@/assets/app_style.css"
// import "@/assets/app_style_dark.css"
// import "@/assets/app_script.js"
// import"@/assets/js/jquery-3.4.1.min.js"
import paginator from '@/views/comps/paginator.vue'
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { dom } from "@fortawesome/fontawesome-svg-core";
library.add(far);
library.add(fab);
library.add(fas);
dom.watch();

import Echo from 'laravel-echo'
window.Pusher = require('pusher-js')

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: '7dc85893a1b1f0775552',
    cluster: 'mt1',
    encrypted: true
})

const app = createApp(App).component("font-awesome-icon", FontAwesomeIcon).component("paginator", paginator).use(store).use(router).use(Toast)

app.config.globalProperties.$filters = {
    timeAgo(date) {
        return moment(date).fromNow()
    },

    dayOnly(date) {
        return moment(date).format("DD")
    },

    monthAndYearOnly(date) {
        return moment(date).format("MMM, YYYY")
    },

    fullDateOnly(date) {
        return moment(date).format("DD MMM, YYYY")
    },

    dateOnly(date) {
        return moment(date).format("DD/MM/YYYY")
    },

    dateTime(date){
        return moment(date).format("YYYY-MM-DD HH:mm:ss")
    },

    timeonly(date){
        return moment(date).format("HH:mm")
    },

    remove_dash(value){
        if (!value) return ''
        return value.replace(/_/g, ' ') 
    },

    currency(value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
}

app.mount('#app')

