<template>
    <nav class="mt-[72px] flex flex-wrap justify-center gap-2.5" v-if="paginatedata.data">
        <a href="#" 
       class="group inline-flex h-12 w-12 items-center justify-center rounded-[50%] bg-[#F5F5F5] font-bold text-colorBlackPearl hover:bg-colorPurpleBlue hover:text-white"
       @click.prevent="get_next_page_data(paginatedata.current_page - 1)">
        <font-awesome-icon :icon="['fas', 'angle-left']" class="opacity-100 group-hover:opacity-0" />
        <font-awesome-icon :icon="['fas', 'angle-left']" class="absolute opacity-0 invert group-hover:opacity-100" />
    </a>
    <!-- Loop through the pages to create pagination buttons dynamically -->
    <a v-for="page in getVisiblePages(paginatedata)"
     :key="page.label"
     href="#"
     class="inline-flex h-12 w-12 items-center justify-center rounded-[50%] bg-[#F5F5F5] font-bold text-colorBlackPearl hover:bg-colorPurpleBlue hover:text-white"
     :class="{'bg-green-600 text-white': paginatedata.current_page === parseInt(page.label)}"
     @click.prevent="get_next_page_data(page)">
     {{ page.label }}
  </a>

    <!-- "Next" button -->
    <a href="#" 
       class="group inline-flex h-12 w-12 items-center justify-center rounded-[50%] bg-[#F5F5F5] font-bold text-colorBlackPearl hover:bg-colorPurpleBlue hover:text-white"
       @click.prevent="get_next_page_data(paginatedata.current_page + 1)">
       <font-awesome-icon :icon="['fas', 'angle-right']" class="opacity-100 group-hover:opacity-0" />
       <font-awesome-icon :icon="['fas', 'angle-right']" class="absolute opacity-0 invert group-hover:opacity-100" />
    </a>
  </nav>

    </template>
    <script>
    import { mapGetters } from 'vuex'
    export default {
        props: {
            paginatedata: {
                type: Object,
                required: true
            },
    
            current_page:{
                type: Number,
                default: 1
            }
        },
    
        computed:{
            ...mapGetters(['loading'])
        },
    
        methods: {
            get_next_page_data(newPage){
                if (newPage < 1 || newPage > this.paginatedata.last_page) return;
                this.$emit('page_changed', newPage);
            },

            getVisiblePages(paginatedata) {
                // Remove first and last links and keep only valid URLs
                let pages = paginatedata.links.slice(1, -1).filter(link => link.url);
                
                if (pages.length <= 4) return pages;

                let currentPage = paginatedata.current_page;
                let total = pages.length;
                let start = Math.max(0, Math.min(currentPage - 2, total - 4));
                
                return pages.slice(start, start + 4);
            }
    
      
        },
    }
    </script>