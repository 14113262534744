export const namespaced = true
import { useToast } from 'vue-toastification'
import axios from 'axios'
const toast = useToast()
export default {

    state : {
        lesson :'',
        lessons :'',   
    },

    getters : {
        lesson: (state )=> state.lesson,
        lessons: (state )=> state.lessons,
    },

    mutations : { 
        setlesson :(state,lesson ) => (state.lesson = lesson), 
        setlessons :(state,lessons ) => (state.lessons = lessons),
    },

    actions :{
        async get_trending_lessons( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_lessons')
            return new Promise((resolve, reject) => {
            axios.post("/open/lessons?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('setlessons', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_lesson_details( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_lesson_details')
            return new Promise((resolve, reject) => {
            axios.post("/open/lessons/" + id, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('setlesson', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }


}