<template>
    <router-view></router-view>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
export default{
  methods:{
      ...mapActions(['getuserlocation','getuseripaddress'])
  },

  created(){
      this.getuseripaddress().then(()=>{
          this.getuserlocation();
      })
  }
}
</script>

